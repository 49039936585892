import { useTranslation } from 'next-i18next';
import type { MouseEvent, ReactElement } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ProductCarouselVideoView } from '~/components/pdp/productDetail/productImageGallery/ProductCarouselVideoView';
import { ProductScrolledView } from '~/components/pdp/productDetail/productImageGallery/ProductScrolledView';
import { useGalleryContext } from '~/utilities/context/dynamic/GalleryContext';
import { useLayoutDesignContext } from '~/utilities/context/dynamic/LayoutDesignContext';
import { useMediaQueryContext } from '~/utilities/context/dynamic/MediaQueryContext';
import { useNavActionContext } from '~/utilities/context/dynamic/NavActionContext';
import { usePDPContext } from '~/utilities/context/static/PDPContext';
import { useStaticContext } from '~/utilities/context/static/StaticContext';

import { useComponentSize } from '~/utilities/dom';
import { NavigationType, VideoMode } from '~/utilities/graphql/codegen';
import type { ProductImageGallery as ProductImageGallerySchema } from '../../../../amplienceTypes/schemas/imported/product-image-gallery-schema';
import type { ProductVideo as ProductVideoSchema } from '../../../../amplienceTypes/schemas/imported/product-video-schema';
import { breakpoints, colors, media } from '../../../shared/core/styles';
import type { CarouselImageProperty, ProductImageProperty } from './ProductImage';
import { createProfileRegExp, preferredImageProfiles } from './ProductImage';
import { ProductZoomImage } from './ProductZoomImage';

export interface ProductImageGalleryHybrisProps {
  productVideo: {
    content: ProductVideoSchema;
    [k: string]: unknown;
  };
}

export type AnimationDirection = 'left' | 'right';

interface SProductImageGalleryProps {
  $hasStickyProductImageGallery?: boolean;
  $hasFlyoutNavigation: boolean;
  $galleryZoomIn: boolean;
  $isCarouselGalleryMode: boolean;
  $sideNavOpened: boolean;
}

const S = {
  ProductImageGallery: styled.div<SProductImageGalleryProps>`
    --width-offset: ${({ $isCarouselGalleryMode, $sideNavOpened }) =>
      $isCarouselGalleryMode || $sideNavOpened ? '42px' : '0px'};

    background-color: ${({ $isCarouselGalleryMode }) =>
      $isCarouselGalleryMode ? colors.IMAGE_GREY : 'white'};
    text-align: center;
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-in-cursor.cur') 18 18, default;
    pointer-events: all;
    transition: max-height 0.25s ease;
    width: ${({ $hasStickyProductImageGallery, $galleryZoomIn }) =>
      $hasStickyProductImageGallery && !$galleryZoomIn
        ? 'min(calc(100% - 360px), calc(var(--width-offset) + (100% / 3) * 2))'
        : '100%'};

    ${({ $hasStickyProductImageGallery }) => css`
      top: ${$hasStickyProductImageGallery ? '0px' : 'initial'};
      z-index: ${$hasStickyProductImageGallery ? '-1' : 'initial'};
      align-self: ${$hasStickyProductImageGallery ? 'flex-start' : 'initial'};
    `}

    @media (prefers-reduced-motion) {
      transition: none;
    }

    ${({ $isCarouselGalleryMode, $hasStickyProductImageGallery, $galleryZoomIn, theme }) => {
      if (!$isCarouselGalleryMode) {
        return css`
          height: ${$galleryZoomIn ? 'calc(100vh - var(--top-nav-height))' : 'auto'};
          overflow: ${$galleryZoomIn ? 'hidden' : 'visible'};
        `;
      }

      return css`
        height: ${$hasStickyProductImageGallery ? 'calc(100vh - var(--header-height))' : '100vw'};

        @media ${media(theme).lessThan('lg')} {
          position: relative;
          top: initial;
          width: 100%;
          z-index: initial;
          height: ${$hasStickyProductImageGallery ? '100vw' : 'auto'};
        }

        ${$hasStickyProductImageGallery &&
        css`
          @media ${media(theme).between('lg', 'xl')} {
            width: calc((100% / 3) * 2);
          }
        `}

        @media ${media(theme).greaterThan('sm')} {
          max-height: ${$hasStickyProductImageGallery ? '66vh' : '500px'};
        }

        @media ${media(theme).greaterThan('lg')} {
          min-height: 520px;
          max-height: ${$hasStickyProductImageGallery ? '100vh' : '675px'};
          width: ${$hasStickyProductImageGallery && !$galleryZoomIn
            ? `calc(var(--width-offset) + (100% / 3) * 2)`
            : '100%'};
        }
      `;
    }}
  `,
  GridCarousel: styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
  `,
  GridCarouselInner: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
  `,
  GridCarouselInnerItem: styled.div<{
    $isDarkImage: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    transform: translateX(calc(100% + var(--scrollbar-width)));

    &:first-child {
      transform: translateX(0);
    }
  `,

  ZoomPanel: styled.div<{ $galleryZoomIn: boolean; $hasStickyProductImageGallery?: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    cursor: url('/_fes/${process.env.BUILD_TIME}/img/zoom/zoom-out-cursor.cur') 18 18, default;
    z-index: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 1 : -1)};
    opacity: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 1 : 0)};
    overflow: auto;
    user-select: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=');

    @media ${({ theme }) => media(theme).lessThan('lg')} {
      display: ${({ $galleryZoomIn }) => ($galleryZoomIn ? 'block' : 'none')};
      width: 100%;
      width: ${({ $hasStickyProductImageGallery }) =>
        $hasStickyProductImageGallery ? '100%' : '100vw'};
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      position: absolute;
      width: auto;
      height: auto;
      right: 0;
      bottom: 0;
      overflow: hidden;
      transition: 600ms;
    }
  `,

  ZoomImage: styled.div`
    position: absolute;
    inset: 0;
    overflow: scroll;
    user-select: none;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      overflow: hidden;
    }
  `,

  ZoomClose: styled.button`
    position: fixed;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background-color: ${colors.NERO_GREY};
    border: none;
    outline: none;

    &::before {
      color: ${colors.WHITE};
      font-family: gstar-icons;
      content: '\\F13F';
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      display: block;
    }
  `,

  MobileVideoPlayButton: styled.div`
    z-index: 20;
    position: absolute;
    text-align: center;
    width: 55px;
    bottom: 20px;
    left: 5px;
    display: block;

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      display: none;
    }
  `,
  SigningsWrapper: styled.div`
    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      position: relative;
    }
  `,
};

export const ProductImageGallery = (
  props: ProductImageGalleryHybrisProps & ProductImageGallerySchema
): ReactElement => {
  const {
    galleryMode,
    autoSlide,
    closeV2,
    arrowLeftV2,
    arrowRightV2,
    productVideo,
    productSignings,
  } = props;
  const {
    product: { simplifiedImages, videos, shouldUseDarkBackgroundImgs = true },
  } = usePDPContext();
  const { galleryZoomIn, setGalleryZoomIn, setScrolledGalleryMode } = useGalleryContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const {
    configuration: { navigationType },
  } = useStaticContext();

  const { isMobile, isMobileOrTablet } = useMediaQueryContext();
  const { sideNavOpened } = useNavActionContext();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const { t } = useTranslation('pdp', { keyPrefix: 'productImageGallery' });

  const zoomPanelRef = useRef<HTMLDivElement>(null);
  const zoomImageWrapperRef = useRef<HTMLDivElement>(null);
  const imageGalleryWrapperRef = useRef<HTMLDivElement>(null);

  const isCarouselGalleryMode = galleryMode !== 'scrolled';
  const video = videos?.find(v => v?.mode === VideoMode.Landscape);

  const containerRect = useMemo(() => {
    if (!zoomPanelRef.current || !galleryZoomIn) {
      return null;
    }

    return zoomPanelRef.current.getBoundingClientRect();
  }, [zoomPanelRef, galleryZoomIn]);

  useEffect(() => {
    setScrolledGalleryMode(galleryMode === 'scrolled' && !isMobileOrTablet);
  }, [galleryMode, setScrolledGalleryMode, isMobileOrTablet]);

  const openGalleryZoomPanel = () => {
    if (galleryZoomIn) {
      return;
    }

    setGalleryZoomIn(true);

    if (hasStickyProductImageGallery) {
      window.scrollTo({ top: 0 });

      if (window.innerWidth < parseInt(breakpoints.lg, 10)) {
        document.body.classList.add('no-scroll');
      }
    }
  };

  const closeGalleryZoomPanel = () => {
    if (!galleryZoomIn) {
      return;
    }

    setGalleryZoomIn(false);
    document.body.classList.remove('no-scroll');

    if (zoomPanelRef.current) {
      zoomPanelRef.current.style.display = 'none';
    }
  };
  const setZoomPanelScrollXCenter = (e: MouseEvent) => {
    openGalleryZoomPanel();

    if (!zoomPanelRef.current) {
      return;
    }

    if (zoomImageWrapperRef.current) {
      zoomPanelRef.current.style.display = 'block';

      if (zoomImageWrapperRef.current.offsetWidth < parseInt(breakpoints.sm, 10)) {
        zoomImageWrapperRef.current.style.left = '0';
        zoomImageWrapperRef.current.scrollLeft =
          (zoomImageWrapperRef.current.scrollWidth - zoomImageWrapperRef.current.offsetWidth) / 2;
      } else {
        zoomPanelRef.current.scrollLeft = 0;

        const panelBounds = containerRect;

        if (panelBounds) {
          const mouseX = e.clientX - panelBounds.left;
          const mouseY = e.clientY - panelBounds.top;

          zoomImageWrapperRef.current.style.left = `-${
            ((2000 - panelBounds.width) / panelBounds.width) * mouseX
          }px`;
          zoomImageWrapperRef.current.style.top = `-${
            ((2000 - panelBounds.height) / panelBounds.height) * mouseY
          }px`;
        }
      }
    }
  };
  const onMouseMoveOnZoomPanel = (e: MouseEvent) => {
    const panelBounds = zoomPanelRef.current?.getBoundingClientRect();

    if (
      panelBounds &&
      panelBounds.width >= parseInt(breakpoints.sm, 10) &&
      zoomImageWrapperRef.current
    ) {
      const mouseX = e.clientX - panelBounds.left;
      const mouseY = e.clientY - panelBounds.top;

      zoomImageWrapperRef.current.style.left = `-${
        ((2000 - panelBounds.width) / panelBounds.width) * mouseX
      }px`;
      zoomImageWrapperRef.current.style.top = `-${
        ((2000 - panelBounds.height) / panelBounds.height) * mouseY
      }px`;
    }
  };

  const productImageList = useMemo((): CarouselImageProperty[] => {
    const profileRegExp = createProfileRegExp(!!shouldUseDarkBackgroundImgs);

    if (simplifiedImages?.entry) {
      const imageListObj: ProductImageProperty[] = [];
      const wideImageListObj: ProductImageProperty[] = [];

      simplifiedImages.entry.forEach(obj => {
        if (!!obj?.key && obj?.value && obj?.value?.url) {
          const profileName = obj.key;

          if (profileName.match(profileRegExp) && preferredImageProfiles.includes(profileName)) {
            const imageObj = {
              url: obj.value.url,
              profile: profileName,
              altText: obj.value.altText || '',
            };

            if (profileName.includes('W')) {
              wideImageListObj.push(imageObj);
            } else {
              imageListObj.push(imageObj);
            }
          }
        }
      });

      imageListObj.sort((a, b) => (a.profile && b.profile && a.profile < b.profile ? -1 : 1));

      if (isMobile && video) {
        imageListObj.splice(2, 0, {
          profile: '',
          url: '',
        });
      }

      const carouselImageListObj: CarouselImageProperty[] = [];

      imageListObj.forEach(original => {
        const wideImage = wideImageListObj.find(
          wideItem => `${original.profile}W` === wideItem.profile
        );

        if (isCarouselGalleryMode && ((isMobile && original) || wideImage)) {
          carouselImageListObj.push({
            original,
            wide: wideImage,
          });
        }

        if (!isCarouselGalleryMode && original) {
          carouselImageListObj.push({
            original,
          });
        }
      });

      return carouselImageListObj;
    }

    return [];
  }, [
    video,
    shouldUseDarkBackgroundImgs,
    simplifiedImages?.entry,
    isMobile,
    isCarouselGalleryMode,
  ]);

  const { height: productDetailInfoHeight } = useComponentSize('.product-detail-info-wrapper', {
    condition: !hasStickyProductImageGallery,
  });

  useEffect(() => {
    if (
      !hasStickyProductImageGallery &&
      imageGalleryWrapperRef.current &&
      productDetailInfoHeight &&
      !isMobile
    ) {
      imageGalleryWrapperRef.current.style.maxHeight = `${productDetailInfoHeight}px`;
    }
  }, [productDetailInfoHeight, hasStickyProductImageGallery, isMobile]);

  return (
    <S.ProductImageGallery
      className="product-image-gallery"
      ref={imageGalleryWrapperRef}
      $hasStickyProductImageGallery={hasStickyProductImageGallery}
      $sideNavOpened={sideNavOpened}
      $hasFlyoutNavigation={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      $galleryZoomIn={galleryZoomIn}
      $isCarouselGalleryMode={isCarouselGalleryMode || isMobileOrTablet}
    >
      {(isCarouselGalleryMode || isMobileOrTablet) && (
        <ProductCarouselVideoView
          autoSlide={autoSlide}
          closeV2={closeV2}
          productImageList={productImageList}
          arrowLeftV2={arrowLeftV2}
          arrowRightV2={arrowRightV2}
          productVideo={productVideo}
          setZoomPanelScrollXCenter={setZoomPanelScrollXCenter}
          productSignings={productSignings}
          currentImageIndex={currentImageIndex}
          setCurrentImageIndex={setCurrentImageIndex}
        />
      )}
      {!isCarouselGalleryMode && !isMobileOrTablet && (
        <ProductScrolledView
          productImageList={productImageList}
          setCurrentImageIndex={setCurrentImageIndex}
          setZoomPanelScrollXCenter={setZoomPanelScrollXCenter}
        />
      )}
      <S.ZoomPanel
        data-testid="image-zoom-panel"
        ref={zoomPanelRef}
        $galleryZoomIn={galleryZoomIn || false}
        onMouseMove={onMouseMoveOnZoomPanel}
        onClick={closeGalleryZoomPanel}
        $hasStickyProductImageGallery={hasStickyProductImageGallery}
      >
        <S.ZoomImage ref={zoomImageWrapperRef}>
          <ProductZoomImage
            url={productImageList[currentImageIndex]?.original.url}
            altText={productImageList[currentImageIndex]?.original.altText}
            isShow={galleryZoomIn}
          />
        </S.ZoomImage>

        <S.ZoomClose
          data-testid="image-zoom-close"
          aria-label={closeV2 || t('close')}
          onClick={closeGalleryZoomPanel}
        />
      </S.ZoomPanel>
    </S.ProductImageGallery>
  );
};
