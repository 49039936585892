import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import type { ColorsValueType } from '../../../../shared/core/styles';
import { colors, media } from '../../../../shared/core/styles';
import { SiteType } from '~/utilities/graphql/codegen';
import { Typography } from '~/components/shared/core/typography/Typography';
import type { SizeButtonStyle } from '~/utilities/constants';
import { sizeButtonStyles } from '~/components/shared/common/styles';

const AafSizesWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const GstarSizesWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
`;

const AafSizeStyle = css<{ $disabled: boolean; $selected: boolean; $btnStyle: SizeButtonStyle }>`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  min-width: 19px;
  height: 28px;
  padding: 0 4px;
  color: ${({ theme, $disabled, $selected }) => {
    let color = theme.colors.content.primary;

    if ($disabled) {
      color = theme.colors.content.secondary;
    }

    if ($selected) {
      color = ' #252523';
    }

    return color;
  }};
  border-width: 0 0
    ${({ $selected }) => {
      let borderWidth = '0px';

      if ($selected) {
        borderWidth = '2px';
      }

      return borderWidth;
    }}
    0;
  border-style: solid;
  border-color: ${({ theme, $selected, $disabled }) => {
    let borderColor = theme.colors.content.primary;

    if ($disabled) {
      borderColor = colors.DISABLED_GREY;
    }

    if ($selected) {
      borderColor = '#252523';
    }

    return borderColor;
  }};
`;

const GstarSizeStyle = css<{ $disabled: boolean; $selected: boolean; $btnStyle: SizeButtonStyle }>`
  width: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].width};
  height: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].height};
  font-size: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].fontSize};
  font-weight: ${({ $selected }) => ($selected ? '700' : '400')};
  padding-left: ${({ $btnStyle }) =>
    $btnStyle === 'sizeRange' && sizeButtonStyles[$btnStyle].paddingLeft};
  padding-right: ${({ $btnStyle }) =>
    $btnStyle === 'sizeRange' && sizeButtonStyles[$btnStyle].paddingRight};
  padding-top: ${({ $selected }) => {
    let paddingTop = '0';

    if ($selected) {
      paddingTop = '1.5px';
    }

    return paddingTop;
  }};
  border-width: 1px 1px
    ${({ $selected }) => {
      let borderWidth = '1px';

      if ($selected) {
        borderWidth = '3px';
      }

      return borderWidth;
    }}
    1px;
  border-style: solid;
  border-color: ${({ $selected, $disabled }) => {
    let borderColor: ColorsValueType = colors.BALI_HAI_GREY;

    if ($disabled) {
      borderColor = colors.DISABLED_GREY;
    }

    if ($selected) {
      borderColor = colors.BLUE_CHARCOAL;
    }

    return borderColor;
  }};
  color: ${({ $disabled }) => {
    let color: ColorsValueType = colors.NERO_BLACK;

    if ($disabled) {
      color = colors.DISABLED_GREY;
    }

    return color;
  }};
  background: ${({ $disabled, $btnStyle }) => {
    let background = 'transparent';

    if ($disabled) {
      background = sizeButtonStyles[$btnStyle].disabledBackgroundMobile;
    }

    return background;
  }};

  @media ${({ theme }) => media(theme).greaterThan('lg')} {
    border-bottom: 1 px solid black;
    border-color: ${({ $selected, $disabled }) => {
      let borderColor: ColorsValueType = colors.RAVEN_GREY;

      if ($disabled) {
        borderColor = colors.BALI_HAI_GREY;
      }

      if ($selected) {
        borderColor = colors.BLUE_CHARCOAL;
      }

      return borderColor;
    }};
    color: ${({ $disabled }) => {
      let color: ColorsValueType = colors.NERO_BLACK;

      if ($disabled) {
        color = colors.BALI_HAI_GREY;
      }

      return color;
    }};
    background: ${({ $disabled, $btnStyle }) => {
      let background = 'transparent';

      if ($disabled) {
        background = sizeButtonStyles[$btnStyle].disabledBackground;
      }

      return background;
    }};
  }
`;

const SizeStylesConfig: Record<
  SiteType,
  RuleSet<{ $disabled: boolean; $selected: boolean; $btnStyle: SizeButtonStyle }>
> = {
  [SiteType.Gstar]: GstarSizeStyle,
  [SiteType.Outlet]: GstarSizeStyle,
  [SiteType.Employeeshop]: GstarSizeStyle,
  [SiteType.Aaf]: AafSizeStyle,
};
const SizesWrapperStylesConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarSizesWrapperStyle,
  [SiteType.Outlet]: GstarSizesWrapperStyle,
  [SiteType.Employeeshop]: GstarSizesWrapperStyle,
  [SiteType.Aaf]: AafSizesWrapperStyle,
};

export const S = {
  Wrapper: styled.div``,

  BellIconWrapper: styled.div`
    position: absolute;
    width: 12px;
    top: 0;
    right: 1px;

    svg path {
      fill: #8a939a;
    }
  `,

  SizeWrapper: styled.div`
    &:not(:first-child) {
      padding-top: 16px;
      padding-bottom: 6px;
    }
  `,

  SizeType: styled(Typography)`
    font-size: 12px;
    font-weight: 400;
    color: ${colors.RAVEN_GREY};
    margin-bottom: 8px;
    text-transform: capitalize;
    line-height: 16px;
  `,

  Sizes: styled.div`
    ${({ theme }) => SizesWrapperStylesConfig[theme.siteType]}
  `,

  Size: styled.div<{ $disabled: boolean; $selected: boolean; $btnStyle: SizeButtonStyle }>`
    ${({ theme }) => SizeStylesConfig[theme.siteType]}

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-right: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].marginRight};
    margin-bottom: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].marginBottom};
    align-items: center;
    cursor: pointer;
    flex-direction: ${({ $btnStyle }) => sizeButtonStyles[$btnStyle].direction};
  `,

  SizeText: styled(Typography)`
    font-size: 11px;
    font-weight: normal;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  Stock: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 12px 0 8px;
  `,

  MiddotWrapper: styled.div`
    display: flex;
    width: 8px;
    height: 8px;
  `,

  StockAvailability: styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
};
